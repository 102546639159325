function sendPaymentDataToAnet(cardData) {
  return new Promise((resolve, reject) => {
    // ...
    var authData = getAuthData();

    cardData.fullName = cardData.name;

    var secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;
    //console.log("The data I'm sending is ", secureData);
    Accept.dispatchData(secureData, (result) => {
      const output = responseHandler(result);
      if (output) {
        resolve(result.opaqueData);
      } else {
        //console.log("send to Auth.net returned an error");

        reject(result);
      }
    });
  });
}

function responseHandler(response) {
  //console.log(response);

  if (response.opaqueData) {
    // paymentFormUpdate(response.opaqueData);
    //console.log(response.opaqueData);
    return true;
  }

  if (response.messages.resultCode === "Error") {
    var i = 0;
    while (i < response.messages.message.length) {
      console.log(
        response.messages.message[i].code +
          ": " +
          response.messages.message[i].text
      );
      i = i + 1;
    }
    return false;
  } else {
    //console.log("unknown error : " + response.messages);
  }
}

export { sendPaymentDataToAnet };
