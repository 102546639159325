// Entry point for the build script in your package.json
import "../assets/javascripts/application.js";
import preventDoubleClicks from "../assets/javascripts/src/utils/preventDoubleClicks.js";
function submitSearch(ev, some) {
  const q = document.querySelectorAll("#query")[0];
  if (q && q.value.length > 3) {
    Rails.fire(this, "submit");
  } else {
    //console.log("nah q is ", q);
  }
}
window.preventDoubleClicks = preventDoubleClicks

document.addEventListener("DOMContentLoaded", () => {
  let searchForm = document.getElementById("search_by_query_form");
  if (searchForm) {
    searchForm.onchange = submitSearch;
  }
});

document.addEventListener("DOMContentLoaded", () => {
  // Google tag (gtag.js)
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16729540922';
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-16729540922');
  `;
  document.head.appendChild(script2);
});

