const showErrorMessage = (msg) => {
  if (msg.response) {
    msg = msg.response.data.error;
  }
  console.log("Error: " + JSON.stringify(msg));
  let box = document.getElementById("error-box");
  box.innerHTML = "<p>" + msg + "</p>";
  box.style.display = "block";

  window.scrollTo({ top: 0, behavior: "smooth" });
};

const showNoticeMessage = (msg) => {
  if (msg.response) {
    msg = msg.response.data.error;
  }
  //console.log("Error: " + msg);
  let box = document.getElementById("notice-box");
  box.innerHTML = "<p>" + msg + "</p>";
  box.style.display = "block";

  // window.scrollTo({ top: 0, behavior: "smooth" });
};

export { showErrorMessage, showNoticeMessage };
