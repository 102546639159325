"use strict";

import { showErrorMessage } from "./src/utils/errorProcessing";
import { sendPaymentDataToAnet } from "./authorize-net";
import { stringify } from "postcss";

function sendToServer(token, authNetToken, data) {
  //console.log("in create sub");
  //console.log("stripe token is ", token);
  //console.log("auth net token is ", authNetToken);
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById("payment-form");
  var hiddenInput = document.createElement("input");
  hiddenInput.setAttribute("type", "hidden");
  hiddenInput.setAttribute("name", "stripeToken");
  hiddenInput.setAttribute("value", token);
  form.appendChild(hiddenInput);

  var hiddenAuthNetInput = document.createElement("input");

  hiddenAuthNetInput.setAttribute("type", "hidden");
  hiddenAuthNetInput.setAttribute("name", "authNetToken");
  hiddenAuthNetInput.setAttribute("value", authNetToken);
  form.appendChild(hiddenAuthNetInput);

  var customerData = document.createElement("input");

  customerData.setAttribute("type", "hidden");
  customerData.setAttribute("name", "customerData");
  data.last4 = data.cardNumber.slice(-4);
  delete data["cardNumber"];

  customerData.setAttribute("value", JSON.stringify(data));
  form.appendChild(customerData);
  // Submit the form
  //console.log("submitting");
  form.submit();
}

function setupCCForm() {
  var form = document.getElementById("payment-form");
  if (!form) return false;

  var resetButton = form.querySelector("a.reset");
  var error = form.querySelector(".error");
  var errorMessage = error.querySelector(".message");

  function enableInputs() {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function (input) {
        input.removeAttribute("disabled");
      }
    );
  }

  function disableInputs() {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function (input) {
        input.setAttribute("disabled", "true");
      }
    );
  }

  function triggerBrowserValidation() {
    // The only way to trigger HTML5 form validation UI is to fake a user submit
    // event.
    var submit = document.createElement("input");
    submit.type = "submit";
    submit.style.display = "none";
    form.appendChild(submit);
    submit.click();
    submit.remove();
  }

  // Listen for errors from each Element, and show error messages in the UI.
  // var savedErrors = {};
  // form.forEach(function (element, idx) {
  //   element.on("change", function (event) {
  //     if (event.error) {
  //       error.classList.add("visible");
  //       savedErrors[idx] = event.error.message;
  //       errorMessage.innerText = event.error.message;
  //     } else {
  //       savedErrors[idx] = null;

  //       // Loop over the saved errors and find the first one, if any.
  //       var nextError = Object.keys(savedErrors)
  //         .sort()
  //         .reduce(function (maybeFoundError, key) {
  //           return maybeFoundError || savedErrors[key];
  //         }, null);

  //       if (nextError) {
  //         // Now that they've fixed the current error, show another one.
  //         errorMessage.innerText = nextError;
  //       } else {
  //         // The user fixed the last error; no more errors.
  //         error.classList.remove("visible");
  //       }
  //     }
  //   });
  // });

  // Listen on the form's 'submit' handler...
  form.addEventListener("submit", function (e) {
    //console.log("we in form submit");
    e.preventDefault();

    // Trigger HTML5 validation UI on the form if any of the inputs fail
    // validation.
    var plainInputsValid = true;
    Array.prototype.forEach.call(
      form.querySelectorAll("input"),
      function (input) {
        if (input.checkValidity && !input.checkValidity()) {
          plainInputsValid = false;
          return;
        }
      }
    );
    if (!plainInputsValid) {
      triggerBrowserValidation();
      return;
    }
    if (form.classList.contains("submitting")) {
      //console.log("Already submitting..");
      return;
    }
    // Show a loading screen...
    form.classList.add("submitting");

    // Disable all inputs.
    disableInputs();

    // Gather additional customer data we may have collected in our form.

    var address1 = document.getElementById("address");
    var city = document.getElementById("city");
    var state = document.getElementById("region");
    var zip = document.getElementById("postal-code");
    var cardNumber = document.getElementById("card-number");
    var expiry = document.getElementById("expiration-date");
    var cvc = document.getElementById("cvc");
    var name = document.getElementById("name");
    var month = document.getElementById("cc-expiry-month");
    var year = document.getElementById("cc-expiry-year");
    var country = document.getElementById("cc-country"); // not here ar the moment but we can add back?
    var data = {
      name: name ? name.value : undefined,
      address_line1: address1 ? address1.value : undefined,
      address_city: city ? city.value : undefined,
      address_state: state ? state.value : undefined,
      address_zip: zip ? zip.value : undefined,
      address_country: country ? country.value : "USA",
      cardNumber: cardNumber ? cardNumber.value : undefined,
      expiry: month.value + "/" + year.value,
      month: month.value,
      year: year.value,
      cvc: cvc ? cvc.value : undefined,
    };

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token. We can also pass
    // in the additional customer data we collected in our form.

    Promise.all([sendDatatoStripe(data), sendPaymentDataToAnet(data)])
      .then(function (result) {
        // Stop loading!
        // form.classList.remove("submitting");
        //console.log(result);
        window.rezzy = result;
        if (result && result[0] && result[0].id) {
          // If we received a token, show the token ID.
          form.classList.add("submitted");

          sendToServer(result[0].id, result[1].dataValue, data);
        } else {
          // Otherwise, un-disable inputs.
          //console.log("no result?");
          enableInputs();
        }
      })
      .catch((e) => {
        enableInputs();
        form = document.getElementById("payment-form");
        form.classList.remove("submitted");
        form.classList.remove("submitting");
        //console.log("errors in the send");
        //console.log(e);
        if (e.messages && e.messages.message && e.messages.message.length > 0) {
          //console.log("messages greater than 0");
          //console.log(e.messages.message);
          showErrorMessage(e.messages.message[0].text);
        }
        //console.log("checking stripe error the e is = ", e);
        if (e.error) {
          //console.log("stripe error");
          showErrorMessage(e.error.message);
        }
      });
  });

  // resetButton.addEventListener("click", function (e) {
  //   e.preventDefault();
  //   // Resetting the form (instead of setting the value to `''` for each input)
  //   // helps us clear webkit autofill styles.
  //   form.reset();

  //   // Clear each Element.
  //   // form.forEach(function (element) {
  //   //   element.clear();
  //   // });

  //   // Reset error state as well.
  //   error.classList.remove("visible");

  //   // Resetting the form does not un-disable inputs, so we need to do it separately:
  //   enableInputs();
  //   form.classList.remove("submitted");
  // });
}

function sendDatatoStripe(data) {
  //console.log("total Data is ", JSON.stringify(data));
  return new Promise((resolve, reject) => {
    let toStripe = {
      number: data.cardNumber,
      cvc: data.cvc,
      exp: data.expiry,
      ...data,
    };
    //console.log("data I'm sending to Stripe is ", JSON.stringify(toStripe));
    Stripe.card.createToken(toStripe, (status, response) => {
      //console.log("status ", status);
      //console.log(response);
      //console.log("response from Stripe is ", JSON.stringify(response));
      if (status == "200") {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
}
function stripeResponseHandler(status, response) {
  //console.log("status is ", status);
  //console.log("response is ", response);
  return response.token;
}

export { setupCCForm };
