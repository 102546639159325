//= require activestorage
import Rails from "@rails/ujs";
import {
  showErrorMessage,
  showNoticeMessage,
} from "./src/utils/errorProcessing.js";
Rails.start();
//console.log("loaded ujs");
import { setupCCForm } from "./cc_tokenizer";
import "./authorize-net";
//console.log("loading application.js");
function read_flash_from_cookies() {
  let flash = JSON.parse(getCookie("flash")) || {};
  if (flash) {
    setCookie("flash", "", -1);
    return flash;
  }
  return null;
}

const processFlash = () => {
  let flash = read_flash_from_cookies();
  console.log("I've read the flash from the cookies", JSON.stringify(flash));
  if (flash.error) {
    showErrorMessage(flash.error.replaceAll("+", " "));
  }
  if (flash.notice) {
    showNoticeMessage(flash.notice.replaceAll("+", " "));
  }
  if (flash.alert) {
    showNoticeMessage(flash.alert.replaceAll("+", " "));
  }
};

document.addEventListener("DOMContentLoaded", () => {
  processFlash();
});
document.addEventListener("load", () => {
  processFlash();
});

document.addEventListener("DOMContentLoaded", () => {
  setupCCForm();
});

var firstVisit;
var bestRef = "google";
var fixUrl = "https://www.google.com";
function trackRef() {
  // eslint-disable-next-line no-useless-escape
  return document.referrer.match(/^https?:\/\/([^\/]+\.)?google\.com(\/|$)/i);
}
function getCookie(c_name) {
  var c_value = document.cookie;
  var c_start = c_value.indexOf(" " + c_name + "=");
  if (c_start == -1) {
    c_start = c_value.indexOf(c_name + "=");
  }
  if (c_start == -1) {
    c_value = null;
  } else {
    c_start = c_value.indexOf("=", c_start) + 1;
    var c_end = c_value.indexOf(";", c_start);
    if (c_end == -1) {
      c_end = c_value.length;
    }
    c_value = unescape(c_value.substring(c_start, c_end));
  }
  return c_value;
}

function setCookie(c_name, value, exdays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value =
    escape(value) + (exdays == null ? "" : "; expires=" + exdate.toUTCString());
  document.cookie = c_name + "=" + c_value;
}

function firstTime() {
  if (firstVisit) return;
  var cookieName = "visit";
  if (!getCookie(cookieName)) {
    firstVisit = true;

    if (trackRef()) {
      window.history.pushState({ page: 1 }, "", "");
      window.addEventListener("popstate", () => {
        window.location = fixUrl;
      });
    }
  } else {
    firstVisit = false;
  }
  setCookie(cookieName, "visit", 365);
}

firstTime();

function copytoclipboard() {
  let containerid = "to-be-copied";
  if (document.selection) {
    let range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select().createTextRange();
    document.execCommand("copy");
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().addRange(range);
    document.execCommand("copy");
  }
}

const selectTab = (name) => {
  Array.prototype.forEach.call(
    document.getElementsByClassName("all-tab-content-plans"),
    (a) => {
      a.classList.add("hidden");
    }
  );

  Array.prototype.forEach.call(
    document.getElementsByClassName("content-" + name),
    (a) => {
      a.classList.remove("hidden");
    }
  );

  Array.prototype.forEach.call(
    document.getElementsByClassName("tab-menu"),
    (a) => {
      if (a.classList.contains(name)) {
        a.classList.add("text-gray-900");
        a.classList.add("rounded-l-lg");

        a.classList.remove("text-gray-500");
      } else {
        a.classList.remove("text-gray-900");
        a.classList.remove("rounded-l-lg");

        a.classList.add("text-gray-500");
      }
    }
  );
  Array.prototype.forEach.call(
    document.getElementsByClassName("bottom-tab"),
    (a) => {
      console.log("checking ", name, " against ", a.classList);
      if (a.classList.contains(name)) {
        console.log("Adding bg-violet-500 to ", JSON.stringify(a), a);
        a.classList.add("bg-violet-500");
        a.classList.remove("bg-transparent");
      } else {
        a.classList.remove("bg-violet-500");
        a.classList.add("bg-transparent");
      }
    }
  );
  document.getElementsByClassName("tab-menu")[0].scrollIntoView();
  event.preventDefault();
  event.stopPropagation();
};

function optionSelectTab(event) {
  console.log("Calling options select tab");
  console.log(event.value);
  selectTab(event.value);
}
window.optionSelectTab = optionSelectTab;
window.selectTab = selectTab;
